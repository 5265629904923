import resell from '../../../assets/images/resell.png'
import asset_profile from '../../../assets/images/asset_profile.png'
import offer from '../../../assets/images/offer.png'
import street_view from '../../../assets/images/street_view.png'
export default function ProfileTab({ tab, openTab }) {
  return (
    <>
      <div className="certificate">
        <div className="custom-nav-tabs">
          <li onClick={() => openTab('details')}>
            <div className="img-div">
              <img src={offer} />
            </div>
            <span>Details</span>
          </li>

          <li onClick={() => openTab('asset')}>
            <div className="img-div">
              <img src={asset_profile} />
            </div>
            <span>Assets</span>
          </li>
        </div>
      </div>
    </>
  )
}
