import buy_dollor from '../../../assets/images/buy_dollor.png'
import asset_profile from '../../../assets/images/asset_profile.png'
import offer from '../../../assets/images/offer.png'
import street_view from '../../../assets/images/street_view.png'
export default function BuyCertificateTab({ tab, openTab }) {
  return (
    <>
      <div className="certificate">
        <div className="custom-nav-tabs">
          <li onClick={() => openTab('asset')}>
            <div className="img-div" style={{ backgroundColor: tab == 'asset' ? '#8364E2' : '' }}>
              <img src={asset_profile} />
            </div>
            <span>Asset Profile</span>
          </li>
          <li onClick={() => openTab('street')}>
            <div className="img-div" style={{ backgroundColor: tab == 'street' ? '#8364E2' : '' }}>
              <img src={street_view} />
            </div>
            <span>Street View</span>
          </li>
          <li onClick={() => openTab('offer')}>
            <div className="img-div" style={{ backgroundColor: tab == 'offer' ? '#8364E2' : '' }}>
              <img src={offer} />
            </div>
            <span>Offer</span>
          </li>
          <li onClick={() => openTab('buy')}>
            <div className="img-div" style={{ backgroundColor: tab == 'buy' ? '#8364E2' : '' }}>
              <img src={buy_dollor} />
            </div>
            <span>Buy</span>
          </li>
        </div>
      </div>
    </>
  )
}
