import React, { useEffect, useState } from 'react'
import CloseIcon from '@mui/icons-material/Close'
import { Col, Row } from 'react-bootstrap'
import nft from '../../assets/images/collection-nft.png'
import apis from '../../services'

const Offer = ({ isOpen, setIsOpen, id }) => {
  const [offers, setOffers] = useState([])

  useEffect(() => {
    ;(async () => {
      const response = await apis.getOffersByBuildingId(123)
      const offers = response?.data?.data
      setOffers(offers)
    })()
  }, [])

  return (
    <div className={'asset-profile primary-scroll ' + (isOpen ? 'resell-show' : '')}>
      <div className="dialog-close-header mb-3">
        <CloseIcon onClick={() => setIsOpen(false)} />
      </div>
      <Row className="gy-4">
        {offers.length > 0 &&
          offers.map((offer) => (
            <Col key={offer._id} lg={4} md={6}>
              <div className="sell-box-layer-1">
                <div className="sell-box-layer-2">
                  <div className="sell-box">
                    <img src={nft} className="nft-img" />
                    <div className="nft-profile circle-div">
                      <span>40%</span>
                    </div>
                  </div>
                  <div className="sell-box-body">
                    <h3>McDonald’s</h3>
                    <h4>291B Oxford St, W1C 2DT, UK</h4>
                    <div className="sell-flex-price">
                      <p>3h 1m 10s</p>
                      <p>0.15 BTC</p>
                    </div>
                  </div>
                  <div className="buttons mainside nft-footer w-100">
                    <a className="d-block">Discount</a>
                  </div>
                </div>
              </div>
            </Col>
          ))}
      </Row>
      <div>{/* https://play.upland.me/static/media/owned-stamp.cacdae76.png */}</div>
    </div>
  )
}

export default Offer
