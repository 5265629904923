import { React, useState } from 'react'
import Dialog from '@mui/material/Dialog'
import DialogContent from '@mui/material/DialogContent'
import { Box, Button, Divider, FormGroup, TextField } from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'
import coins from '../../../assets/dash/coin-money.png'
import house from '../../../assets/dash/House.png'
import manWithMoney from '../../../assets/dash/money-vector-man.png'

import CustomDialog from '../../Dialog'
import { Clear } from '@mui/icons-material'
import ProfileTab from './ProfileTab'

function AssetProfile({ isOpen, setIsOpen, b_data }) {
  const data = [
    {
      name: '42 Oxford Street London',
      country: 'UK',
      city: 'London',
      price: '20,000 USDG',
    },
    {
      name: '42 Oxford Street London',
      country: 'UK',
      city: 'London',
      price: '20,000 USDG',
    },
  ]
  return (
    <>
      <div className={'assets primary-scroll ' + (isOpen ? 'resell-show' : '')}>
        <div className="dialog-close-header">
          <CloseIcon onClick={() => setIsOpen(false)} />
        </div>
        <div className="container">
          <div className="mb-3">
            <FormGroup row>
              <TextField
                required
                id="place"
                label="Search Place"
                name="palce"
                autoComplete="place"
                onChange={(e) => {}}
              />
              <Button variant="contained" disableElevation>
                Search
              </Button>
            </FormGroup>
          </div>
          <div className="text-center ">
            <h4
              className="text-center"
              style={{
                borderTop: '1px solid black',
                borderBottom: '1px solid black',
              }}
            >
              Properties
            </h4>
          </div>
          <div className="row">
            {data.map((item, i) => (
              <div key={i} className="d-flex ">
                <span className="text-center" style={{ border: '1px solid black', width: '30px' }}>
                  {' '}
                  {i + 1}{' '}
                </span>
                <span style={{ border: '1px solid black', padding: '15px' }}>
                  <h4 style={{ color: 'blue' }}>{item.name}</h4>
                  <div>
                    {item.city}, {item.country}
                  </div>
                  <div> {item.price}</div>
                </span>
              </div>
            ))}
          </div>
        </div>
      </div>
    </>
  )
}

function Details({ isOpen, setIsOpen }) {
  return (
    <>
      <div className={'asset-profile primary-scroll ' + (isOpen ? 'resell-show' : '')}>
        <div className="dialog-close-header mb-3">
          <CloseIcon onClick={() => setIsOpen(false)} />
        </div>
        <div className="main-asset overflow-auto primary-scroll">
          <div className="display-3">User Profile Details</div>
        </div>
      </div>
    </>
  )
}

export default function UserProfileData({
  status,
  toggleModalProfile,

  data,
  position,
  refreshMapHandler,
  mapBoxData,
}) {
  const [previewImage, setPreviewImage] = useState(false)
  const [isOpen, setIsOpen] = useState(true)
  const [tab, setTab] = useState('')

  const [modalStatus, setModalStatus] = useState(false)

  const handleToggle = () => setModalStatus((prevState) => !prevState)

  const openTab = (e) => {
    setTab(e)
    setIsOpen(true)
  }

  return (
    <>
      <div>
        <Dialog
          open={status}
          onClose={toggleModalProfile}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          fullWidth
          maxWidth={'sm'}
          className="certificate-modal"
        >
          <DialogContent className="primary-scroll">
            <ProfileTab tab={tab} openTab={openTab} />
            <div className="container" style={{ height: '400px' }}>
              <div className="d-flex justify-content-around mt-4">
                <span style={{ backgroundColor: 'white', borderRadius: '30px' }}>
                  <img
                    src={'https://res.cloudinary.com/dot-pic/image/upload/v1659617513/Avatar_3_zaxygb.png'}
                    style={{
                      padding: '10px',
                      width: '100px',
                    }}
                    alt="avatar"
                  ></img>
                </span>
                <span>
                  <div
                    className="userName-profile"
                    style={{
                      fontWeight: 'bold',
                      color: 'white',
                      fontSize: '30px',
                    }}
                  >
                    USER NAME
                  </div>

                  <Divider style={{ backgroundColor: 'gray' }} />
                  <div
                    className="level-profile"
                    style={{
                      fontWeight: 'bold',
                      color: 'white',
                      fontSize: '20px',
                    }}
                  >
                    BASIC LEVEL USER
                  </div>
                </span>
              </div>
              <div className="mt-4">
                <div className="h2 text-center mt-4 " style={{ color: 'white' }}>
                  User Asset Balance
                </div>
                <div className="d-flex justify-content-around mt-2">
                  <span className="text-center">
                    <div className="img-div" style={{ border: 'none', padding: '0px' }}>
                      <img src={coins} style={{ width: '50px' }} />
                    </div>
                    <span
                      style={{
                        color: 'white',
                        fontSize: '20px',
                        fontWeight: 'bold',
                      }}
                    >
                      Coins
                    </span>
                  </span>
                  <span className="text-center">
                    <div className="img-div" style={{ border: 'none', padding: '0px' }}>
                      <img src={house} style={{ width: '150px' }} />
                    </div>
                    <span
                      style={{
                        color: 'white',
                        fontSize: '20px',
                        fontWeight: 'bold',
                      }}
                    >
                      Coins
                    </span>
                  </span>
                  <span className="text-center">
                    <div className="img-div" style={{ border: 'none', padding: '0px' }}>
                      <img src={manWithMoney} style={{ width: '50px' }} />
                    </div>
                    <span
                      style={{
                        color: 'white',
                        fontSize: '20px',
                        fontWeight: 'bold',
                      }}
                    >
                      Coins
                    </span>
                  </span>
                </div>
              </div>
            </div>

            {tab == 'asset' ? (
              <AssetProfile setIsOpen={setIsOpen} isOpen={isOpen} />
            ) : (
              tab == 'details' && <Details setIsOpen={setIsOpen} isOpen={isOpen} />
            )}
          </DialogContent>
        </Dialog>

        {/* TODO:: **********       SALE DIALOG     ************/}
        <CustomDialog status={modalStatus} toggleModal={toggleModalProfile} title="Sales Price">
          <div className="modal-main-heading" style={{ display: 'flex' }}>
            <Box
              style={{
                display: 'flex',
                justifyContent: 'center',
                width: '90%',
              }}
              variant="Top"
            >
              Sell Your Items{' '}
            </Box>
            <Box style={{ display: 'flex', width: '10%', cursor: 'pointer' }} onClick={handleToggle}>
              <Clear />
            </Box>
          </div>
          <Divider style={{ backgroundColor: 'gray' }} />
        </CustomDialog>
      </div>
    </>
  )
}
