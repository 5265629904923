import { React, useEffect, useState, useTransition } from 'react'
import { ethers } from 'ethers'
import { toast } from 'react-toastify'
import { Dialog, DialogContent } from '@mui/material'
import { useWeb3React } from '@web3-react/core'
import Geocode from 'react-geocode'

import star from '../../../assets/images/star.png'

import ABI from '../../../contract/GameRee1155.json'
import TokenABI from '../../../contract/USDG.json'

import DepositModal from './DepositModal'
import deposit from '../../../helpers/deposit'
import PreviewImage from './PreviewImage'
import apis from '../../../services'
import BuyCertificateTab from './BuyCertificateTab'
import { loadProvider } from '../../../helpers'
import { USDG_addr, NFT_addr } from '../../../contract/addresses'
import { AssetProfile, Buy, Offer, Street } from '../../CertificatetTabs'

export default function BuyDigitalCerfiticate(props) {
  const { status, toggleModal, data, position, refreshMapHandler, mapboxData } = props
  const { account } = useWeb3React()
  const token_Type = '57896044618658097711785492504343953927315557066662158946655541218820101242880'
  const googleMapsApiKey = 'AIzaSyA4bN_JLbgMsrsaspEm1ebHDiTNNvE7DTA'

  const [isLoading, setLoading] = useState(false)
  const [isOpen, setIsOpen] = useState(true)
  const [tab, setTab] = useState('')
  const openTab = (e) => {
    setTab(e)
    setIsOpen(true)
  }

  const closeTab = (status) => {
    setTab('')
    setIsOpen(status)
  }
  useEffect(() => {
    async function fetchData() {
      Geocode.setApiKey(googleMapsApiKey)
      Geocode.fromLatLng(position.lat, position.lng).then((response) => {
        console.log('response from geocode by lat,lng', response)
      })
    }
    fetchData()
  }, [position])

  const b_data = {}
  b_data['buildingName'] = data?.['building_name'] || ''
  b_data['buildingNumber'] = data?.['Building number'] || ''
  b_data['name'] = data?.['owner'] || ''
  b_data['noOfUnits'] = data?.['total_units'] || ''
  b_data['totalAreaInSqt'] = data?.['total_gross_area_sqt'] || ''
  b_data['city'] = data?.['city'] || ''
  b_data['currentNFT_Price'] = data?.['current_nft_price'] || ''
  b_data['priceInEuro'] = data?.['initial_price']
  b_data['image'] = data?.['image']
  b_data['owner'] = data?.['owner'] || ''
  b_data['postCode'] = data?.['postal_code']
  b_data['pricePerSquare'] = data?.['price_per_square'] || ''
  b_data['roadName'] = data?.['street_name']
  b_data['streetName'] = data?.['street_name']
  b_data['id'] = data?.['id'] || 0
  b_data['_account'] = data?.['account'] || ''
  var _account = b_data['_account']

  const [balance, setBalance] = useState(0.0)
  const [depositValue, setDepositValue] = useState(0)
  const [modalStatus, setModalStatus] = useState(false)
  const [previewImage, setPreviewImage] = useState(false)
  const [isPending, startTransition] = useTransition()
  const [amountValue, setAmountValue] = useState(0)
  const [btnTxt, setBtnText] = useState('Not Listed')

  const handleToggle = () => setModalStatus((prevState) => !prevState)

  const onChangeHandler = (e) => {
    setDepositValue(e.target.value)
    startTransition(async () => {
      const signer = await loadProvider()
      const TokenContract = new ethers.Contract(USDG_addr, TokenABI, signer)
      const amount = ethers.utils.parseEther(e.target.value)
      const getPrice = await TokenContract.getPrice(amount)
      setAmountValue(ethers.utils.formatUnits(getPrice.toString(), '8'))
    })
  }
  const deposit1 = () => {
    deposit(account, depositValue, handleToggle, loadProvider)
  }

  const mint = async () => {
    try {
      //PINATA LOGIC
      // console.log(data, '********************');
      const metaDataURI = await apis.pinataJSONToIPFS(data)

      setLoading(true)
      let signer = await loadProvider()
      let NFTCrowdsaleContract = new ethers.Contract(NFT_addr, ABI, signer)
      let TokenContract = new ethers.Contract(USDG_addr, TokenABI, signer)
      console.log('BALANCE', TokenContract)
      let balance = await TokenContract?.balanceOf(account)
      console.log('BALANCE', balance)

      if (balance.toString() !== '0') {
        toast.info('Minting Process Started')
        let approve = await TokenContract.approve(NFT_addr, '100000000000000000000000000')
        let tx1 = await approve.wait()
        // console.log(tx1)

        if (tx1.confirmations > 0) {
          let mint = await NFTCrowdsaleContract.mintNonFungibleWithURI(token_Type, [account], [], metaDataURI)
          await mint.wait()
          const mintedID = await NFTCrowdsaleContract._lastTokenId()
          await apis.saveMapNFT({
            id: data.id.toString(),
            nft_id: mintedID.toString(),
          })
        }

        toast.success('Successfully Minted')
        toggleModal()
        refreshMapHandler()
        setLoading(false)
      } else {
        setLoading(false)
        toast.error('You Dont have USDG tokens')
      }
    } catch (e) {
      console.error('data', e)
      setLoading(false)
      toast.error('some error occurred can not mint')
      // setError('some error occurred can not mint')
    }
  }
  useEffect(() => {
    ;(async () => {
      if (account) {
        try {
          const signer = await loadProvider()
          const TokenContract = new ethers.Contract(USDG_addr, TokenABI, signer)
          const _balance = await TokenContract.balanceOf(account)
        } catch (e) {
          console.log(e)
        }
      }
    })()
  }, [account])
  return (
    <>
      <div>
        <Dialog
          open={status}
          onClose={toggleModal}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          fullWidth
          maxWidth={'md'}
          className="certificate-modal "
        >
          {/* <DialogTitle id="alert-dialog-title">
          {"Use Google's location service?"}
        </DialogTitle> */}
          <DialogContent className="primary-scroll">
            <BuyCertificateTab tab={tab} openTab={openTab} />
            <div className="certificate-body">
              <div className="d-flex  justify-content-around ">
                <div className="nft-circle">
                  <img src={b_data['image']} className="nft-img" onClick={() => setPreviewImage(true)} />
                  <div className="rating">
                    <img src={star} />
                    <img src={star} />
                    <img src={star} />
                  </div>
                  <div className="tag">
                    <span>This Property is for Sale</span>
                  </div>
                </div>
                <div
                  className="nft-name custom-shadow"
                  style={{
                    backgroundColor: 'white',
                    borderRadius: '20px',
                    margin: '10px',
                    border: '1px solid #484646',
                  }}
                >
                  <div
                    className="building_name"
                    style={{
                      backgroundColor: 'lightblue',
                      color: 'white',
                      padding: '0px 80px',
                      borderTopRightRadius: '20px',
                      borderTopLeftRadius: '20px',
                      borderBottom: '1px solid black',
                    }}
                  >
                    <h4>{b_data['buildingName']}</h4>
                    <h5>{b_data['streetName']}</h5>
                  </div>
                  <div
                    className="owner details"
                    style={{
                      backgroundColor: 'white',
                      borderBottom: '1px solid black',
                    }}
                  >
                    <div style={{ color: 'silver', fontSize: '10px' }}>owner</div>
                    <div
                      className="text-center"
                      style={{
                        color: 'blue',
                        fontWeight: 'bold',
                        fontSize: '20px',
                      }}
                    >
                      SIR KHALIL
                    </div>
                  </div>
                  <div
                    className="property size"
                    style={{
                      backgroundColor: 'white',
                      borderBottom: '1px solid black',
                    }}
                  >
                    <div style={{ color: 'silver', fontSize: '10px' }}>Property Size Sqft</div>
                    <div
                      className="text-center"
                      style={{
                        color: 'black',
                        fontWeight: 'bold',
                        fontSize: '20px',
                      }}
                    >
                      97
                    </div>
                  </div>

                  <div className="d-flex justify-content-between">
                    <div className="property size" style={{ backgroundColor: 'white' }}>
                      <div style={{ color: 'silver', fontSize: '10px' }}>Monthly Views</div>
                      <div
                        className="text-center"
                        style={{
                          color: 'black',
                          fontWeight: 'bold',
                          fontSize: '20px',
                        }}
                      >
                        106
                      </div>
                    </div>
                    <div className="property size" style={{ backgroundColor: 'white' }}>
                      <div style={{ color: 'silver', fontSize: '10px' }}>Property Value</div>
                      <div
                        className="text-center"
                        style={{
                          color: 'black',
                          fontWeight: 'bold',
                          fontSize: '20px',
                        }}
                      >
                        50 USDG
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* <ul className='building-detail digital-building-detail'>
                            <li>
                                <p> OWNER </p>
                                <input className='form-control primary-input' value={'Gameree'} readOnly/>
                            </li>
                            <li>
                                <p> TOTAL PURCHASE PRICE </p>
                                <input className='form-control primary-input' value={b_data['currentNFT_Price']}
                                       readOnly/>
                            </li>
                            <li>
                                <p> USDG Price </p>
                                <input className='form-control primary-input' value={b_data['currentNFT_Price']} readOnly/>
                            </li>
                            <li>
                                <p> USDG Size </p>
                                <input className='form-control primary-input' value={'not_available'} readOnly/>
                            </li>
                        </ul>
                         */}
              <div className="text-center mt-5">
                <button className="custom-btn primary-btn" onClick={mint} disabled={isLoading}>
                  {isLoading ? 'Minting' : 'Mint'}
                </button>
              </div>
            </div>
            {tab === 'buy' ? (
              <Buy setIsOpen={closeTab} isOpen={isOpen} balance={balance} handleToggle={handleToggle} />
            ) : tab === 'asset' ? (
              <AssetProfile setIsOpen={closeTab} isOpen={isOpen} b_data={b_data} />
            ) : tab === 'offer' ? (
              <Offer setIsOpen={closeTab} isOpen={isOpen} id={b_data['id']} />
            ) : (
              tab === 'street' && (
                <Street
                  setIsOpen={closeTab}
                  isOpen={isOpen}
                  // position={position}
                  mapBoxData={mapboxData}
                />
              )
            )}
          </DialogContent>
        </Dialog>
        <DepositModal
          isPending={isPending}
          amountValue={amountValue}
          deposit={deposit1}
          modalStatus={modalStatus}
          handleToggle={handleToggle}
          depositValue={depositValue}
          onChangeHandler={onChangeHandler}
        />
        <PreviewImage status={previewImage} toggleModal={setPreviewImage} image={b_data['image']} />
      </div>
    </>
  )
}
