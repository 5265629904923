import React from 'react'
import CloseIcon from '@mui/icons-material/Close'
import { Col, Form, Row } from 'react-bootstrap'

const AssetProfile = ({ isOpen, setIsOpen, b_data }) => {
  return (
    <>
      <div className={'asset-profile primary-scroll ' + (isOpen ? 'resell-show' : '')}>
        <div className="dialog-close-header">
          <CloseIcon onClick={() => setIsOpen(false)} />
        </div>
        <div className="main-asset ">
          <Row>
            <Col lg={4} md={4}>
              <img src={b_data['image']} className="nft-img" />
            </Col>
            <Col lg={8} md={8}>
              <Form className="custom-form-main row-col-text">
                <div>
                  <Form.Group className="form-group custom-primary-group" controlId="roadname">
                    <Form.Label>Road Name:</Form.Label>
                    <Form.Control value={b_data['roadName']} />
                  </Form.Group>
                  <Form.Group className="form-group custom-primary-group" controlId="post_code">
                    <Form.Label>Post Code:</Form.Label>
                    <Form.Control value={b_data['postCode']} />
                  </Form.Group>
                  <Form.Group className="form-group custom-primary-group" controlId="units">
                    <Form.Label>No. of units:</Form.Label>
                    <Form.Control value={b_data['noOfUnits']} />
                  </Form.Group>
                  <Form.Group className="form-group custom-primary-group" controlId="units">
                    <Form.Label>No. of units:</Form.Label>
                    <Form.Control value={b_data['noOfUnits']} />
                  </Form.Group>
                  <Form.Group className="form-group custom-primary-group" controlId="nft_price">
                    <Form.Label>NFT Price $:</Form.Label>
                    <Form.Control value={b_data['currentNFT_Price']} />
                  </Form.Group>
                </div>
                <div>
                  <Form.Group className="form-group custom-primary-group" controlId="street_name">
                    <Form.Label>Street Name:</Form.Label>
                    <Form.Control value={b_data['streetName']} />
                  </Form.Group>
                  <Form.Group className="form-group custom-primary-group" controlId="country">
                    <Form.Label>Country:</Form.Label>
                    <Form.Control value={'not_available'} />
                  </Form.Group>
                  <Form.Group className="form-group custom-primary-group" controlId="gross">
                    <Form.Label>No. of total gross:</Form.Label>
                    <Form.Control value={'not_available'} />
                  </Form.Group>
                  <Form.Group className="form-group custom-primary-group" controlId="BUSDG">
                    <Form.Label>Price BUSDG:</Form.Label>
                    <Form.Control value={'not_available'} />
                  </Form.Group>
                  <Form.Group className="form-group custom-primary-group" controlId="asset_price">
                    <Form.Label>Real world asset price:</Form.Label>
                    <Form.Control value={'not_available'} />
                  </Form.Group>
                </div>
                {/* <Form.Group className="form-group custom-primary-group" controlId="street_name">
                                <Form.Label>Street Name:</Form.Label>
                                <Form.Control value={b_data['streetName']}/>
                            </Form.Group>
                            <Form.Group className="form-group custom-primary-group" controlId="country">
                                <Form.Label>Country:</Form.Label>
                                <Form.Control value={"not_available"}/>
                            </Form.Group>
                            <Form.Group className="form-group custom-primary-group" controlId="units">
                                <Form.Label>No. of units:</Form.Label>
                                <Form.Control value={b_data['noOfUnits']}/>
                            </Form.Group>
                            <Form.Group className="form-group custom-primary-group" controlId="gross">
                                <Form.Label>No. of total gross:</Form.Label>
                                <Form.Control value={"not_available"}/>
                            </Form.Group>
                            <Form.Group className="form-group custom-primary-group" controlId="BUSDG">
                                <Form.Label>Price BUSDG:</Form.Label>
                                <Form.Control value={"not_available"}/>
                            </Form.Group>
                            <Form.Group className="form-group custom-primary-group" controlId="nft_price">
                                <Form.Label>NFT Price $:</Form.Label>
                                <Form.Control value={b_data['currentNFT_Price']}/>
                            </Form.Group>
                            <Form.Group className="form-group custom-primary-group" controlId="asset_price">
                                <Form.Label>Real world asset price:</Form.Label>
                                <Form.Control value={"not_available"}/>
                            </Form.Group> */}
              </Form>
            </Col>
          </Row>
        </div>
      </div>
    </>
  )
}

export default AssetProfile
