import React, { useCallback, useEffect, useRef, useState } from 'react'
import mapboxgl from '!mapbox-gl' // eslint-disable-line import/no-webpack-loader-syntax
import '@mapbox/mapbox-gl-geocoder/dist/mapbox-gl-geocoder.css'

import { ethers } from 'ethers'
import { NFT_addr } from '../../../contract/addresses'
import ABI from '../../../contract/GameRee1155.json'
import Web3Modal from 'web3modal'
import GooglePlacesAutocomplete, { geocodeByPlaceId } from 'react-google-places-autocomplete'

import apis from '../../../services'
import { getPerSqPrice, getTotalAreaRandomArea, getTotalPrice } from '../../../helpers/measurements'
import { BuyDigitalCerfiticate, DigitalCerfiticate } from '../modals'
import MapLoader from '../../MapLoader'
import getBuildingImage from '../../../helpers/getBuildingImage'
import { useWeb3React } from '@web3-react/core'
import { toast } from 'react-toastify'
import { navigate } from '@reach/router'
import { useEagerConnect, useInactiveListener } from '../../../hooks/useEagerConnect'
import { AiOutlineCloseCircle } from 'react-icons/ai'
import CircularProgress from '@mui/material/CircularProgress'
import { useSelector } from 'react-redux'
import StreetView from './streetView'
import Dialog from '@mui/material/Dialog'
import DialogTitle from '@mui/material/DialogTitle'
import DialogContent from '@mui/material/DialogContent'
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import ListItemButton from '@mui/material/ListItemButton'
import ListItemText from '@mui/material/ListItemText'
import UserProfileData from '../modals/UserProfileModal'
import GoogleSearch from './GoogleSearch'
import ApiLink from '../../pages/ApiLink'

// mapboxgl.accessToken = process.env.REACT_APP_MAPBOX_TOKEN;
mapboxgl.accessToken = `pk.eyJ1IjoiaGFzZWViYWJiYXNpMDAiLCJhIjoiY2wyejVqcWVsMDkzcjNjbDdocWI4dzA0cSJ9.mB8mVHePsaB0wmqbIE9f1Q`

const VIEWS = [
  { view: 'Street View', pitch: 90 },
  { view: 'Drone View', pitch: 60 },
  { view: 'Top View', pitch: 0 },
]

export default function Mapbox() {
  const [buildingPics, SetBuildingPics] = useState([])
  const [address, setAddress] = useState()
  const [showUserInfo, setUserInfo] = useState(false)
  const [hoverData, SetHoverData] = useState()

  const [mapboxData, SetMapBoxData] = useState()

  let [markers, setMarkers] = useState([])

  const [mapProps, setMapProps] = useState()
  const [MapNavigate, setMapNavigate] = useState()
  const [viewCounter, setCounter] = useState(2)

  const [zoom, setZoom] = useState(18)
  const [open, setOpen] = useState(false)

  const [dimensionViews, setDimViews] = useState(true)

  const [buildingData, SetBuilding] = useState()
  const [loading, setLoading] = useState(false)
  const [modalIsOpen, setIsOpen] = useState(false)
  const [modalIsOpenProfile, setIsOpenProfile] = useState(false)
  const [modalIsOpenSearch, setIsOpenSearch] = useState(false)

  const [ProfileIsOpen, setProfileIsOpen] = useState(false)
  const [owner, setOwner] = useState('')
  const [position, setPosition] = useState({ lat: 0, lng: 0 })
  const [mapLoader, setMapLoader] = useState(true)
  const [refreshMap, setRefreshMap] = useState(false)
  const [errorMsg, setErrorMsg] = useState(false)

  const { account, library } = useWeb3React()
  useEagerConnect(setErrorMsg)
  useInactiveListener()
  const handleClickOpen = () => {
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
  }

  // const map = useRef(null);
  const mapContainer = useRef('')
  // const [pitch, setPitch] = useState(30);

  const [lng, setLng] = useState(-0.141099)
  const [lat, setLat] = useState(51.515419)
  const state = useSelector((state) => state.user)

  const [metadata, setMetadata] = useState([])
  const [isMinted, setMinting] = useState(false)

  const { user } = useSelector((store) => store.user)

  const getAllMintedIds = useCallback(async () => {
    // const {data} = await apis.getAllMintedIds();
    const { data } = await apis.getNFTCategoryIDs()
    return data
  }, [])

  const loadProvider = async () => {
    try {
      const web3Modal = new Web3Modal()
      const connection = await web3Modal.connect()
      const provider = new ethers.providers.Web3Provider(connection)
      return provider.getSigner()
    } catch (e) {
      console.log('loadProvider: ', e)
    }
  }

  const getMetadata = async (id) => {
    try {
      // console.log('***************', id);
      let signer = await loadProvider()
      let NFTCrowdsaleContract = new ethers.Contract(NFT_addr, ABI, signer)
      const caccount = await signer.getAddress()

      let uri = await NFTCrowdsaleContract.uri(id)
      let owner
      try {
        owner = await NFTCrowdsaleContract.ownerOf(id)
      } catch (error) {
        owner = '0x0000000000000000000000000000000000000000'
      }
      let response = await fetch(uri, { method: 'GET' })
      const data = await response.json()
      // data.owner = owner
      data.id = id
      data.account = caccount

      return data
    } catch (e) {
      console.error('data', e)
    }
  }

  const toggleModal = () => setIsOpen((prevState) => !prevState)

  const toggleModalProfile = () => setIsOpenProfile((prevState) => !prevState)
  const toggleModalSearch = () => setIsOpenSearch((prevState) => !prevState)

  const refreshMapHandler = () => setRefreshMap((prevState) => !prevState)

  const certificateProps = {
    toggleModal,
    status: modalIsOpen,
    data: buildingData,
    loading,
    position,
    refreshMapHandler,
    // mapboxData: mapboxData?.features[0]?.place_name,
    // mapboxData: mapboxData?.features[0],

    zoom: zoom,
  }
  const showProfileProps = {
    toggleModalProfile,
    status: modalIsOpenProfile,
    loading,
    refreshMapHandler,
  }
  const showSearchProps = {
    status: modalIsOpenSearch,
    toggleModalSearch,
  }
  useEffect(() => {
    async function fetchData() {
      //  fetch(`${ApiLink}/getAll`)
    }
    // fetchData();
  })

  useEffect(() => {
    if (!state.user) {
      navigate(`/signIn`)
    }

    setTimeout(() => setMapLoader(false), 5000)

    const map = new mapboxgl.Map({
      container: mapContainer.current,
      style:
        dimensionViews === true
          ? 'mapbox://styles/haseebabbasi00/cl6nxm56n002p15qof1ujufem'
          : 'mapbox://styles/haseebabbasi00/cl7dq8yd7000215octt1khn3o',
      width: '100wh',
      attributionControl: false,
      pitch: VIEWS[viewCounter].pitch,
      center: [-0.141099, 51.515419],
      zoom: 18,
      bearing: 80,
      layers: [
        {
          id: 'background',
          type: 'background',
          layout: {},
          paint: {
            'background-color': ['white'],
          },
        },
        {},
      ],
    })

    /**
     *
     *  Profile Component
     * */
    class ProfileComponent {
      constructor({ bearing = -20, pitch = 70, minpitchzoom = null }) {
        this._bearing = bearing
        this._pitch = pitch
        this._minpitchzoom = minpitchzoom
      }

      onAdd(map) {
        this._map = map
        let _this = this

        this._btn = document.createElement('button')
        this._btn.className = 'mapboxgl-ctrl-icon mapboxgl-ctrl-profile'
        this._btn.type = 'button'
        this._btn.innerHTML = ` 
        <img src="https://res.cloudinary.com/dot-pic/image/upload/v1659617513/Avatar_3_zaxygb.png"
          style="width: 40px; height: 40px;"
          alt="avatar"
          ></img>
        `
        this._btn['aria-label'] = 'Toggle Pitch'
        this._btn.onclick = function () {
          setUserInfo(true)
          setIsOpenProfile(true)
        }

        this._container = document.createElement('div')
        this._container.className = 'mapboxgl-ctrl-group mapboxgl-ctrl'
        this._container.appendChild(this._btn)

        return this._container
      }

      onRemove() {
        this._container.parentNode.removeChild(this._container)
        this._map = undefined
      }
    }

    /**
     *  View Change Component
     * */
    class PitchToggle {
      constructor({ bearing = -20, pitch = 70, minpitchzoom = null }) {
        this._bearing = bearing
        this._pitch = pitch
        this._minpitchzoom = minpitchzoom
      }

      onAdd(map) {
        this._map = map
        let _this = this

        this._btn = document.createElement('button')
        this._btn.className = 'mapboxgl-ctrl-icon mapboxgl-ctrl-pitchtoggle-3d'
        this._btn.type = 'button'
        this._btn['aria-label'] = 'Toggle Pitch'
        this._btn.onclick = function () {
          if (map.getPitch() === 0) {
            // let options = { pitch: _this._pitch, bearing: _this._bearing };
            // if (_this._minpitchzoom && map.getZoom() > _this._minpitchzoom)
            // {
            //   options.zoom = _this._minpitchzoom;
            // }
            map.easeTo({ pitch: 60, bearing: 80 })

            _this._btn.className = 'mapboxgl-ctrl-icon mapboxgl-ctrl-pitchtoggle-2d'
          } else {
            map.easeTo({ pitch: 0, bearing: 80 })
            _this._btn.className = 'mapboxgl-ctrl-icon mapboxgl-ctrl-pitchtoggle-3d'
          }
        }

        this._container = document.createElement('div')
        this._container.className = 'mapboxgl-ctrl-group mapboxgl-ctrl'
        this._container.appendChild(this._btn)

        return this._container
      }

      onRemove() {
        this._container.parentNode.removeChild(this._container)
        this._map = undefined
      }
    }

    class SearchComponent {
      constructor({ minpitchzoom = null }) {
        this._minpitchzoom = minpitchzoom
      }

      onAdd(map) {
        this._map = map
        let _this = this

        this._btn = document.createElement('button')
        this._btn.className = 'mapboxgl-ctrl-icon mapboxgl-ctrl-profile'
        this._btn.type = 'button'
        this._btn.innerHTML = ` 
        <img src="https://img.icons8.com/dotty/2x/search.png "
        style="width: 40px; height: 40px;"
          
          alt="avatar"
          ></img>
        `
        this._btn['aria-label'] = 'Toggle Pitch'
        this._btn.onclick = function () {
          setIsOpenSearch(true)
        }

        this._container = document.createElement('div')
        this._container.className = 'mapboxgl-ctrl-group mapboxgl-ctrl'
        this._container.appendChild(this._btn)

        return this._container
      }

      onRemove() {
        this._container.parentNode.removeChild(this._container)
        this._map = undefined
      }
    }

    const nav = new mapboxgl.NavigationControl()
    map.addControl(nav, 'bottom-right')
    map.addControl(new PitchToggle({ minpitchzoom: 11 }), 'bottom-right')
    map.addControl(new ProfileComponent({ minpitchzoom: 11 }), 'top-right')
    map.addControl(new SearchComponent({ minpitchzoom: 11 }), 'bottom-right')

    map.on('load', async () => {
      const layer = map.getStyle().layers.at(-1)

      const layerID = layer.id
      const { sale, minted } = await getAllMintedIds()

      // console.log("############", { sale, minted });

      const saleCondition = ['in', ['id'], ['literal', sale]]
      const mintCondition = ['in', ['id'], ['literal', minted]]
      const paint = ['case', saleCondition, '#EB906E', mintCondition, '#808080', '#800080']

      map.setPaintProperty(layerID, 'fill-extrusion-color', paint)
    })
    map.on('click', async (e) => {
      const point = e.lngLat
      setProfileIsOpen(false)
      var features = map.queryRenderedFeatures(e.point)
      const _id = features[0]?.id

      // console.log(_id, "***********");
      const buildingLayerId = features[1]?.layer?.id

      if (buildingLayerId === 'C100') {
        const { lat, lng } = e.lngLat
        fetch(
          `https://api.mapbox.com/geocoding/v5/mapbox.places/${point.lng},${point.lat}.json?limit=1&access_token=pk.eyJ1IjoiaGFzZWViYWJiYXNpMDAiLCJhIjoiY2wyejVqcWVsMDkzcjNjbDdocWI4dzA0cSJ9.mB8mVHePsaB0wmqbIE9f1Q`
        )
          .then((data) => data.json())
          .then((json) => {
            // console.log(json);
            SetMapBoxData(json)
          })
          .catch((e) => {
            console.log(e)
          })

        // const res = await fetch(
        //   `https://api.mapbox.com/geocoding/v5/mapbox.places/donut.json?type=address&proximity=${lat},${lng}&access_token=${process.env.REACT_APP_MAPBOX_TOKEN}`
        // );
        // const jsonData = await res.json();
        // console.log(jsonData, "************");

        // return;

        // console.log(e.lngLat);
        setPosition({ lat, lng })

        setLoading(true)
        setMinting(false)

        const response = await apis.getMapNFTData(_id)
        const mintedId = response?.data?.mintedId || ''

        if (mintedId) {
          const data = await getMetadata(mintedId)
          // console.log(data, "$$$$$$$$$$$$");
          SetBuilding(data)
          setMinting(true)
          setIsOpen(true)
          setLoading(false)
          return
        }

        const username = user.userName || 'GameRee'
        const area = getTotalAreaRandomArea()
        const total = getTotalPrice(area)
        const pricePerSquare = getPerSqPrice()

        apis
          .getGoogleData(lat, lng)
          .then((res) => res.data)
          .then(async (json) => {
            // console.log("*********** SELECTED_CITY ******", json);
            const row = json.results[0]
            const [building_name, street_name, city] = row.formatted_address.split(',')

            const placeData = {
              id: _id,
              name: 'GameRee',
              building_name,
              street_name,
              city,
              owner: username,
              // postal_code,
              total_units: 'Not Available',
              total_gross_area_sqt: area,
              price_per_square: pricePerSquare,
              initial_price: total,
              current_nft_price: 0.5,
              description: 'GameRee NFT minted from GameRee website',
              image: await getBuildingImage(_id),
            }

            SetBuilding(placeData)
            setIsOpen(true)
            setLoading(false)
          })
          .catch((e) => {
            setLoading(false)
          })
      }
    })
    map.on('mousemove', (e) => {
      const point = e.lngLat

      // `e.point` is the x, y coordinates of the `mousemove` event
      // relative to the top-left corner of the map.
      setLng(point.lng)
      setLat(point.lat)

      var features = map.queryRenderedFeatures(e.point)

      /**
       * checks the layer then fetches the building data on the hover on the building on the basis of lng,lat
       * */
      if (features[0]) {
        fetch(
          `https://api.mapbox.com/geocoding/v5/mapbox.places/${point.lng},${point.lat}.json?limit=1&access_token=pk.eyJ1IjoiaGFzZWViYWJiYXNpMDAiLCJhIjoiY2wyejVqcWVsMDkzcjNjbDdocWI4dzA0cSJ9.mB8mVHePsaB0wmqbIE9f1Q`
        )
          .then((data) => data.json())
          .then((json) => {
            SetHoverData(json)
            // SetMapBoxData(json);
          })
          .catch((e) => {})
      }
    })

    map.on('move', (e) => {
      setLng(map.getCenter().lng)
      setLat(map.getCenter().lat)
      setZoom(map.getZoom().toFixed(4))
    })
    setMapNavigate(map)
    return () => {
      map.remove()
    }
  }, [metadata, refreshMap, dimensionViews])

  return (
    <>
      <div>
        <Dialog
          open={open}
          onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">{'Select   Buildings  Areas '}</DialogTitle>
          <DialogContent>
            <List>
              <ListItem
                onClick={() => {
                  MapNavigate.flyTo({
                    pitch: 0,
                    zoom: 18,
                    center: [-0.1304631464227839, 51.51641177208569],
                  })
                  setOpen(false)
                }}
                disablePadding
              >
                <ListItemButton>
                  <ListItemText primary="1. Oxford Street, London," />
                </ListItemButton>
              </ListItem>

              <ListItem
                onClick={() => {
                  MapNavigate.flyTo({
                    pitch: 0,
                    zoom: 18,
                    center: [-0.14203501935114105, 51.51527717368762],
                  })

                  setOpen(false)
                }}
                disablePadding
              >
                <ListItemButton>
                  <ListItemText primary="2. Oxford Street, London," />
                </ListItemButton>
              </ListItem>
              <ListItem
                onClick={() => {
                  MapNavigate.flyTo({
                    pitch: 0,
                    zoom: 18,
                    center: [-0.15779513234576825, 51.51350268739273],
                  })
                  setOpen(false)
                }}
                disablePadding
              >
                <ListItemButton>
                  <ListItemText primary="3. Oxford Street, London," />
                </ListItemButton>
              </ListItem>
            </List>
          </DialogContent>
        </Dialog>

        <div className="container text-center mb-1">
          <div className="row">
            <div className="col-md-1 col-xs-5 offset-xs-1">
              <button className="btn-main" onClick={handleClickOpen}>
                Buildings Area
              </button>
            </div>
            <div className="col-md-1 offset-md-1 col-xs-5 offset-xs-1">
              <button
                className="btn-main"
                onClick={() => {
                  setDimViews(!dimensionViews)
                }}
              >
                2D/3D views
              </button>
            </div>

            <div className="col-md-6 offset-md-3 col-xs-12">
              <div>
                <GooglePlacesAutocomplete
                  apiKey="AIzaSyBxZ5mUOwo3CUldbWrsKCZyeVJVffyP8AU"
                  minLengthAutocomplete={3}
                  onChange={() => {}}
                  selectProps={{
                    isClearable: true,
                    value: address,
                    onChange: (val) => {
                      setAddress(address)

                      const func = async () => {
                        const geocodeObj = val && val.value && (await geocodeByPlaceId(val.value.place_id))
                        if (geocodeObj) {
                          markers.forEach((marker) => marker.remove())

                          const place = geocodeObj[0]
                          /**
                           *  markers and flying to view searched building
                           * */
                          const popUp = new mapboxgl.Popup({ offset: 25 }) // add popups
                            .setHTML(`<h3>${place.types[0]}</h3><p>${place.formatted_address}</p>`)
                          const el = document.createElement('div')
                          el.className = 'marker'
                          const marker = new mapboxgl.Marker(el)
                            .setLngLat([place.geometry.location.lng(), place.geometry.location.lat()])
                            .setPopup(popUp)
                            .addTo(MapNavigate)

                          setMarkers([...markers, marker])

                          MapNavigate.flyTo({
                            pitch: 0,
                            zoom: 18,
                            center: [place.geometry.location.lng(), place.geometry.location.lat()],
                          })
                          setAddress('')
                          /**
                           *  markers and flying to view searched building
                           * */
                        }
                      }

                      func()
                    },
                  }}
                />
              </div>

              {}
            </div>
          </div>
        </div>

        <div className="sidebar_2">
          {' '}
          Longitude: {lng.toFixed(4)} | Latitude: {lat.toFixed(4)} | Zoom: {zoom} {/*| Pitch {pitch}*/}{' '}
        </div>

        {hoverData && <div className="sidebar">{`${hoverData?.features[0]?.place_name}`}</div>}

        {mapLoader && <MapLoader />}

        <div style={{ height: '100vh' }} ref={mapContainer} className="map-container" />
      </div>
      {isMinted ? (
        <DigitalCerfiticate {...certificateProps} />
      ) : (
        <BuyDigitalCerfiticate {...certificateProps} mapboxData={mapboxData?.features[0]?.place_name} />
      )}
      {modalIsOpenProfile && <UserProfileData {...showProfileProps} />}

      {modalIsOpenSearch && (
        <GoogleSearch
          address={address}
          setAddress={setAddress}
          markers={markers}
          setMarkers={setMarkers}
          MapNavigate={MapNavigate}
          {...showSearchProps}
        />
      )}
    </>
  )
}
