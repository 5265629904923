import resell from '../../../assets/images/resell.png'
import asset_profile from '../../../assets/images/asset_profile.png'
import offer from '../../../assets/images/offer.png'
import street_view from '../../../assets/images/street_view.png'

export default function CertificateTab({ tab, openTab }) {
  return (
    <>
      <div className="certificate">
        <div className="custom-nav-tabs">
          <li onClick={() => openTab('asset')}>
            <div className="img-div">
              <img src={asset_profile} />
            </div>
            <span>Certificate</span>
          </li>
          <li onClick={() => openTab('street')}>
            <div className="img-div">
              <img src={street_view} />
            </div>
            <span>Street View</span>
          </li>
          <li onClick={() => openTab('offer')}>
            <div className="img-div">
              <img src={offer} />
            </div>
            <span>Offer</span>
          </li>
          <li onClick={() => openTab('resell')}>
            <div className="img-div">
              <img src={resell} />
            </div>
            <span>Resell</span>
          </li>
        </div>
      </div>
    </>
  )
}
