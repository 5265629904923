import GooglePlacesAutocomplete, { geocodeByPlaceId } from 'react-google-places-autocomplete'
import Dialog from '@mui/material/Dialog'
import DialogContent from '@mui/material/DialogContent'

import mapboxgl from '!mapbox-gl' // eslint-disable-line import/no-webpack-loader-syntax
import { useState } from 'react'

mapboxgl.accessToken = `pk.eyJ1IjoiaGFzZWViYWJiYXNpMDAiLCJhIjoiY2wyejVqcWVsMDkzcjNjbDdocWI4dzA0cSJ9.mB8mVHePsaB0wmqbIE9f1Q`

const GoogleSearch = (props) => {
  const { MapNavigate, address, status, toggleModalSearch, setAddress, markers, setMarkers } = props

  return (
    <>
      <div>
        <Dialog
          open={status}
          onClose={toggleModalSearch}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          fullWidth
          maxWidth={'sm'}
          className="certificate-modal"
          style={{ height: '400px' }}
        >
          <DialogContent className="primary-scroll" style={{ height: '400px' }}>
            <GooglePlacesAutocomplete
              apiKey="AIzaSyBxZ5mUOwo3CUldbWrsKCZyeVJVffyP8AU"
              minLengthAutocomplete={3}
              onChange={() => {}}
              selectProps={{
                isClearable: true,
                value: address,
                onChange: (val) => {
                  setAddress(address)

                  const func = async () => {
                    const geocodeObj = val && val.value && (await geocodeByPlaceId(val.value.place_id))
                    if (geocodeObj) {
                      markers.forEach((marker) => marker.remove())

                      const place = geocodeObj[0]
                      /**
                       *  markers and flying to view searched building
                       * */
                      const popUp = new mapboxgl.Popup({ offset: 25 }) // add popups
                        .setHTML(`<h3>${place.types[0]}</h3><p>${place.formatted_address}</p>`)
                      const el = document.createElement('div')
                      el.className = 'marker'
                      const marker = new mapboxgl.Marker(el)
                        .setLngLat([place.geometry.location.lng(), place.geometry.location.lat()])
                        .setPopup(popUp)
                        .addTo(MapNavigate)

                      setMarkers([...markers, marker])

                      MapNavigate.flyTo({
                        pitch: 0,
                        zoom: 18,
                        center: [place.geometry.location.lng(), place.geometry.location.lat()],
                      })
                      setAddress('')
                      /**
                       *  markers and flying to view searched building
                       * */
                    }
                  }

                  func()
                },
              }}
            />
          </DialogContent>
        </Dialog>
      </div>
    </>
  )
}
export default GoogleSearch
